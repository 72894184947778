<template>
  <div v-bind:style="styles">
    <div id="page-content" v-if="!viLoader">
      <div class="webNew-dispflex">
       
       <div class="backbox" style="margin-left: 3px;">
     <div class="back-arrow" @click="previousRoute">
       <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
    
     
     </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        FEE PAYMENT 
       </h4>
     </div>
      <div class="row ml-2 mr-2">
        <div class="col-lg-12">
          <div class="widjet">
            <div>
              <div
                class="dispflex pt-3"
                
              >
                <div class="dk_icon">
                 
                </div>
                <div class="dk_icon">
                  <div
                          class="widjethdr dispflex"
                          style="align-items: baseline; padding: 10px 0;border-bottom: none;"
                        >
                          <div style="width: 122%">
                            <span class="has-float-label">
                              <select
                                class="form-control form-input"
                                id="deptlistid"
                                @change="getSelectRange"
                                v-model="selectAcademicYear"
                              >
                                <option
                                  v-for="(item, index) in academicYearList"
                                  :key="index"
                                  :value="item._id"
                                >
                                  {{ item.from }} - {{ item.to }}
                                </option>
                              </select>
                              <label for="acyearid"
                                >Academic Year
                                <span class="required">*</span></label
                              >
                            </span>
                          </div>
                        </div>
                </div>
                <div class="dk_icon">
                 
                </div>
               
              </div>
              
            </div>
            <div class="p-3 widjetcontent">
              <div class="widjetcontent tbllist">
                    <div>
                      <div class="crtfeeplancont">
                       
                        <div class="restable" >
                          <div class="resrow resheader">
                            <div class="cell" >Fee Type</div>
                            <div class="cell">Plan Name</div>
                            <div class="cell" >Fee Amount ($)</div>
                            <div class="cell">Due Date</div>
                            <div class="cell">Fee Status</div>
                            <div class="cell">Action</div>
                          </div>
                          <div
                            class="resrow"
                            v-for="(data, index) in studentFeePlanList"
                            :key="index"
                          >
                            <div class="cell" data-title="Plan Name">
                              {{ data.type }}
                            </div>
                            <div class="cell" data-title="Plan Name">
                              {{ data.planName }}
                            </div>
                             <div class="cell" data-title="Plan Name" v-if="data.type == 'Term-Fees'">
                              {{ data.termFeeSummary.length > 0 ? getTotalFees(data.termFeeSummary) : 0 }}
                            </div>
                            <div class="cell" data-title="Plan Name" v-if="data.type == 'Adhoc-Fees'">
                              {{ data.adhocFeeSummary.length > 0 ? getTotalAdhocFees(data.adhocFeeSummary) : 0 }}
                            </div>
                            <div class="cell" data-title="Plan Name">
                              {{ data.feesEndDate | dateFormat }}
                            </div>
                            <div
                              class="cell"
                              data-title="Plan Name"
                              :style="
                                (data.status == 'Not Paid' || data.status == 'Cancelled' )
                                  ? 'color : darkorange;'
                                  : 'color : lightgreen'
                              "
                            >
                              {{ data.status }}
                            </div>
                            <div class="cell" data-title="Action">
                              <a
                                v-if="data.type == 'Term-Fees'"
                                href="javascript:void(0);"
                                @click.prevent="
                                  viewStudentTerms(
                                    data.studentId,
                                    data.feePlanId,
                                    data
                                  )
                                "
                                >View</a
                              >
                              <a
                                v-if="data.type == 'Adhoc-Fees'"
                                href="javascript:void(0);"
                                @click.prevent="
                                  viewStudentAdhocTerms(
                                    data.studentId,
                                    data.adhocFeeId
                                  )
                                "
                                >View</a
                              >
                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    <b-modal id="showSummaryPopUp" no-close-on-backdrop no-close-on-esc>
     <!-- <button type="button" aria-label="Close" class="close"  @click.prevent="closeSummaryPopUp">×</button> -->
      <div style="margin-bottom: 5%">
         
        <div class="form-row" style="width: 100%">
          <div class="form-group input-group col-md-12 dispflex">
            <!-- <span class="close">&times;</span> -->
            <h2>Term Fee Summary List</h2>
            <div class="text-right mr-1" style="margin-left: 43%;">
              <button style="width: fit-content;"
              @click.prevent="paymentConfirm"
              type="button"
              class="btn btnsml"
              id="svbtn"
            >
            Proceed to pay
            </button>
                  <!-- <button
                    type="button"
                    @click="previousRoute"
                    class="backbtn"
                    id="clbtn"
                  >CLose
                    
                  </button> -->
                </div>
                <div class="text-right mr-1">
              <button
              @click.prevent="closeSummaryPopUp"
              type="button"
              class="btn btncl"
              id="clbtn"
            >
             Close
            </button>
                  <!-- <button
                    type="button"
                    @click="previousRoute"
                    class="backbtn"
                    id="clbtn"
                  >CLose
                    
                  </button> -->
                </div>
            <!-- <button class="btn gry_bg tooltipt mt-1"  @click.prevent="paymentConfirm">
              <i class="fas fa-file-invoice"></i>
             <span class="tooltiptext">Proceed to Pay</span>
            </button> -->
          </div>
          
         
          <!-- <div  class="dispflex form-row showamt">
            <div class="form-group input-group dispflex alert alert-info p-2 w33" v-if="totalSumAmount > 0 && schoolDetails.convenienceFee">
            <span>Total Amount:</span> <span>Rs. {{ totalSumAmount }}</span>
          </div>
          <div class="form-group input-group  dispflex alert alert-info p-2 w33" v-if="totalSumAmount > 0 && schoolDetails.convenienceFee">
             <span v-if="schoolDetails.convenienceFee == 'Rs($)'" style="display:flex;justify-content:space-between;width:100%">
            <span v-if="schoolDetails.convenienceFee == 'Rs($)'">Convenience Fee:</span>  <div><span>{{ schoolDetails.convenienceFee }}</span><span> {{ schoolDetails.convenienceFeeAmount }}</span></div>
            </span>
            <span v-if="schoolDetails.convenienceFee == 'Percentage(%)'">
            <span v-if="schoolDetails.convenienceFee == 'Percentage(%)'" >ConvenienceFee {{ schoolDetails.convenienceFee }}</span> {{ schoolDetails.convenienceFeePercentage }}
          </span>
          </div>
          <div class="form-group input-group dispflex alert alert-info p-2 w33" v-if="totalSumAmount > 0 && schoolDetails.convenienceFee">
            <span>Total Amount:</span><span>Rs. {{ addConvienceFeeAmount(totalSumAmount) }}</span>

          </div>
          </div> -->
        <!--  <div class="form-group input-group col-md-1" style="margin: 0">
            <button
              @click.prevent="closeSummaryPopUp"
              type="button"
              class="btn btncl clsmdl"
              id="clbtn"
            >
                 <span>Close</span>
            </button>
          </div>-->
        </div>
        <div
          class="form-row"
          v-if="
            studentSibilingDetails &&
            studentSibilingDetails.siblings &&
            studentSibilingDetails.isSiblingDiscountApplied
          "
          style="width: 100%; margin-top: 2%; margin-bottom: 0%;border: 1px solid rgb(233, 222, 222);"
        >
        <div class="form-group input-group col-md-2" style="font-weight: bold;margin: 0">
           Student Siblings Discount :
          </div>
          <div class="form-group input-group col-md-2" style="padding-top: 9px ">
            <span class="has-float-label">
              <input
                disabled
                :value="studentSibilingDetails.siblings ? 'Yes' : 'No'"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label style="width: 121%;" for="stadrid">Eligible for Sibling Discount </label>
            </span>
          </div>
          <div class="form-group input-group col-md-3" style="padding-top: 9px ">
            <span class="has-float-label">
              <input
                disabled
                v-model="studentSibilingDetails.siblingDiscount"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Discount</label>
            </span>
          </div>
          <div class="form-group input-group col-md-5" style="padding-top: 9px ">
            <span class="has-float-label">
              <v-select
                class="form-control form-input"
                disabled
                label="label"
                name="AssignClasses"
                multiple
                v-model="studentSibilingDetails.toWards"
                :options="towardsAllList"
                :reduce="(label) => label.value"
              ></v-select>
              <label for="stadrid">Towards</label>
            </span>
          </div>
        </div>
        <div
          class="form-row"
          v-if="
            studentDiscountDetails &&
            studentDiscountDetails.studentDiscountEnabled &&
            studentDiscountDetails.isStudentDiscountEnabledDiscountApplied && currentStudentStatus != 'Cancelled'
          "
          style="width: 100%; margin-top: 2%; margin-bottom: 0%;border: 1px solid rgb(233, 222, 222);"
        >
        <div class="form-group input-group col-md-2" style="font-weight: bold;margin: 0">
           Student Discount :
          </div>
          <div class="form-group input-group col-md-2" style="padding-top: 9px ">
            <span class="has-float-label">
              <input
                disabled
                :value="studentDiscountDetails.studentDiscountEnabled ? 'Yes' : 'No'"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Eligible for Student Discount </label>
            </span>
          </div>
          <div class="form-group input-group col-md-3" style="padding-top: 9px ">
            <span class="has-float-label">
              <input
                disabled
                v-model="studentDiscountDetails.studentDiscountAmount"
                type="text"
                name="stadr"
                class="form-control form-input"
                id="stadrid"
              />
              <label for="stadrid">Discount</label>
            </span>
          </div>
          <div class="form-group input-group col-md-5" style="padding-top: 9px ">
            <span class="has-float-label">
              <v-select
                class="form-control form-input"
                disabled
                label="label"
                name="AssignClasses"
                multiple
                v-model="studentDiscountDetails.studentDiscountToWards"
                :options="towardsAllList"
                :reduce="(label) => label.value"
              ></v-select>
              <label for="stadrid">Towards</label>
            </span>
          </div>
         
          
        </div>
        <br />
        <div class="contact-form">
          <div v-if="Object.keys(studentBillingSummary).length > 0">
            <div class="admintbl pb-3 mt-1" style="border: 1px solid #e9dede;">
              
                <div class="row cust-bg">
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Select</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Term Name</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Fees Type</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Invoice Date</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Invoice Due Date</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Billing Frequency</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Discount</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Fee Amount ($)</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Penalty</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Status</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Paid Date</div>
                  <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">Total Amount ($)</div>
                </div>
                <div
                  v-for="(data, index) in Object.keys(studentBillingSummary)"
                  :key="index"
                >
                  <div
                    class="row cust-lr"
                    :style="
                      studentBillingSummary[data][data][0].status == 'Paid'
                        ? 'color : lightgreen;background-color: darkgray;text-align: center;'
                        : 'color : darkorange'
                    "
                  >
                    <div class="cell chkbox col-1" data-title="Select" style="text-align: center;padding-left: 25px;">
                      <label
                        class="custcheckbox"
                        :style="
                          studentBillingSummary[data][data][0].status == 'Paid' || studentBillingSummary[data][data][0].status == 'Cancelled'
                            ? 'cursor: no-drop;' : '' "
                      >
                        <input
                          type="checkbox"
                          checked="checked"
                          v-if="
                            studentBillingSummary[data][data][0].status ==
                            'Paid'
                          "
                          disabled
                        />

                        <input
                          type="checkbox"
                          v-else
                          @change="
                            checkSelected(
                              data,
                              studentBillingSummary[data].isSelected,
                              index
                            )
                          "
                          :disabled="studentBillingSummary[data][data][0].status == 'Paid' || studentBillingSummary[data][data][0].status == 'Cancelled'"
                          v-model="studentBillingSummary[data].isSelected"
                        />
                        <span
                          class="checkmarkchk"
                          :style="
                            studentBillingSummary[data][data][0].status ==
                            'Paid'
                              ? 'background-color: #e0d0c3;'
                              : ''
                          "
                        ></span>
                      </label>
                    </div>
                    <div class="col-8 cell" style="font-weight: bold;padding-left: 18px;">
                      {{ data }}
                    </div>

                    <div class="col-1 cell" style="font-weight: bold;text-align: center;">
                      {{
                        studentBillingSummary[data][data][0].status
                          ? studentBillingSummary[data][data][0].status
                          : "-"
                      }}
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;text-align: center;">
                      {{
                        studentBillingSummary[data][data][0].paidDate
                          ? showDateFormat(
                              studentBillingSummary[data][data][0].paidDate
                            )
                          : "-"
                      }}
                    </div>
                    <div
                      class="col-1 cell"
                      style="font-weight: bold; color: black;text-align: center;"
                    >
                    {{ showFeePlanDiscount == true ? totalFeesTermWithDiscount(data) : (totalFeesTerm(data)) }}

                      <!-- {{ totalFeesTerm(data) | currencyFormat }} -->
                    </div>
                  </div>
                  <div
                    class="row cust-lr"
                    v-for="(ter, issndex) in studentBillingSummary[data][data]"
                    :key="issndex"
                  >
                    <div class="col-2 cell" ></div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">{{ ter.feeType ? ter.feeType.name : '' }}</div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      {{ ter.feesStartDate | dateFormat }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      {{ ter.feesEndDate | dateFormat }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      {{ ter.billingFrequency }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      {{ ter.isDiscount ? showFeePlanDiscount ?  getdiscountAmountTerm(ter.amount) : '-' : "-" }} 
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      {{ ter.amount | currencyFormat }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;"> {{ ter.penaltyAmount || '-' }}</div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">{{ ter.status }}</div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;">
                      {{ ter.paidDate ? showDateFormat(ter.paidDate) : "-" }}
                    </div>
                    <div class="col-1 cell" style="border: 1px solid #e9dede;text-align: center;"></div>
                  </div>
                </div>
                <div style="border-style: groove; margin-top: 2%;">
                  <div class="row" v-if="showFeePlanDiscount">
                    <div class="col-4 cell" style="font-weight: bold;"></div>

                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Discount Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;text-align: center;">
                      {{ getDiscountAmount }}
                    </div>
                    <!-- <div class="col-1 cell" style="font-weight: bold;"></div> -->
                    <div
                      class="col-4 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                    Total Amount With Discount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;text-align: center;">
                      {{ totalSumAmount | currencyFormat }}
                    </div>
                  </div>
                  <div class="row" v-else>
                    <div class="col-4 cell" style="font-weight: bold;"></div>

                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Discount Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;text-align: center;">
                      {{ getDiscountAmount }}
                    </div>
                    <!-- <div class="col-1 cell" style="font-weight: bold;"></div> -->
                    <div
                      class="col-4 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                    Total Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;text-align: center;">
                      {{ totalSumAmount | currencyFormat }}
                      <!-- {{ totalSumAmount | currencyFormat }} -->
                    </div>
                  </div>

                  <!-- <div class="row">
                    <div class="col-9 cell" style="font-weight: bold"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold; text-align-last: end"
                    >
                      Total Amount    :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;text-align: center;">
                      {{ totalSumAmount | currencyFormat }}
                    </div>
                  </div> -->
                  <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold;"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold;text-align-last: end;"
                    >
                      Penalty Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;;text-align: center;">
                      {{ getPenaltyAmountOnly }}
                    </div>
                  </div>
                  <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold"> </div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold; text-align-last: end"
                    >
                      Sibling Discount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;text-align: center;">
                      {{ adminOverrideDiscount }}
                    </div>
                  </div>
                  <!-- <div class="row cust-lr">
                    <div class="col-8 cell" style="font-weight: bold"></div>
                    <div
                      class="col-3 cell"
                      style="font-weight: bold; text-align-last: end"
                    >
                      Plan Discount Amount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;text-align: center;">
                      {{ getDiscountAmount }}
                    </div>
                  </div> -->
                  <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold"> </div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold; text-align-last: end"
                    >
                      Student Discount :
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;text-align: center;">
                      {{ adminOverrideStudentDiscount }}
                    </div>
                  </div>
                  
                  <div class="row cust-lr" >
                    <div class="col-9 cell" style="font-weight: bold"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold; text-align-last: end"
                    >
                    Convenience Fee : 
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;text-align: center;" v-if="schoolDetails && schoolDetails.convenienceFee != 'Percentage(%)'"> 
                      {{ totalSumAmount ? (convenienceFeeAmount | currencyFormat) : 0 }}
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;text-align: center;" v-if="schoolDetails && schoolDetails.convenienceFee == 'Percentage(%)'"> 
                      {{ totalSumAmount ? ( getconvenienceFeePercentageAmount | currencyFormat) : 0 }}
                    </div>
                  </div>
                  
                  <div class="row cust-lr">
                    <div class="col-9 cell" style="font-weight: bold"></div>
                    <div
                      class="col-2 cell"
                      style="font-weight: bold; text-align-last: end"
                    >
                    Payable Amount :  
                    </div>
                    <div class="col-1 cell" style="font-weight: bold;text-align: center;">
                      {{ grandTotalAmount > 0 ? ((grandTotalAmount + convenienceFeeAmount) | currencyFormat) : (grandTotalAmount | currencyFormat)}}
                    </div>
                  </div>
                </div>
              
            </div>
            <div class="alert alert-info mt-3" style="padding: 0.35rem 0.65rem">
              <ul style="margin: 0">
                <li v-if="planDetails.discount">
                  {{
                    planDetails.discount && planDetails.discount.mode
                      ? planDetails.discount.mode == "Rs($)"
                        ? "Rs" + " " + planDetails.discount.discountAmountValue
                        : planDetails.discount.discountPercentageValue + "%"
                      : "0%"
                  }}
                  discount is applicable if paid initially for full term within
                  the due date
                </li>
                <li v-if="planDetails.penalty">
                  {{
                    planDetails.penalty && planDetails.penalty.mode
                      ? planDetails.penalty.mode == "Rs($)"
                        ? "Rs" + " " + planDetails.penalty.penaltyAmountValue
                        : planDetails.penalty.penaltyPercentageValue + "%"
                      : "0%"
                  }}
                  Late Fee charges are levied if the fees are not paid within
                  the due date
                </li>
                <li>
                  Sibling Discount will be applicable if the student is eligible
                  and it will reflect in Student Fee Summary Section
                </li>
                <li>
                  *Discount & other charges are applied/included in Final
                  Billing Summary
                </li>
              </ul>
            </div>
           
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="showAdhocFeeSummaryPopUp" no-close-on-backdrop no-close-on-esc>
      <div style="margin-bottom: 5%">
        <div class="form-row" style="width: 100%">
          <div class="form-group input-group col-md-10" style="margin: 0">
            <!-- <span class="close">&times;</span> -->
            <h2>Adhoc Fee Summary</h2>
          </div>
          <div class="form-group input-group col-md-1" style="margin-left: 5%;">
            <div class="text-right mr-1">
              <button
              @click.prevent="closeAdhocSummaryPopUp"
              type="button"
              class="btn btncl"
              id="clbtn"
            >
             Close
            </button>
                  <!-- <button
                    type="button"
                    @click="previousRoute"
                    class="backbtn"
                    id="clbtn"
                  >CLose
                    
                  </button> -->
                </div>
           
          </div>
        </div>
        <br />
        <div class="contact-form">
          <div
            v-if="
              studentAdhocFeesList.length > 0 &&
              studentAdhocFeesList[0].feeLineItem.length > 0
            "
          >
            <div class="admintbl mt-1 p-3">
              <table border="1">
                <thead>
                  <tr>
                    <td scope="col" rowspan="4" style="text-align: center;">
                      Fee Name<span class="required">*</span>
                    </td>
                    <td scope="col" rowspan="4" style="text-align: center;">
                      Amount<span class="required">*</span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in studentAdhocFeesList[0].feeLineItem"
                    :key="index"
                  >
                    <td data-label="Fee Name" style="text-align: center;">
                      <input type="text" style="text-align: center;" disabled v-model="item.name" />
                    </td>
                    <td data-label="Fee Name" >
                      <input style="text-align: center;" type="text" disabled v-model="item.netAmount" />
                    </td>
                  </tr>
                  <tr style="background: #e7e7e7; color: #444444">
                    <td style="color: #2954a2;text-align: center;"><b>Total Amount</b></td>
                    <td  style="text-align: center;">
                      <b>Rs: {{ getTotalNetAmount() }}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="widjet mt-4 w-100"
              v-if="studentAdhocFeesList[0].status != 'Paid'"
            >
              <div class="widjethdr dispflex">
                <div style="width: auto">
                  <span class="iconsect blubg" style="padding-left: 9px"
                    ><i class="far fa-list-alt"></i
                  ></span>
                  <h1>Payment</h1>
                </div>
              </div>

              <div class="widjetcontent p-3" >
                <div class="form-row">
                  <div class="form-group input-group col-md-2 mt-3" v-if="adhocFeeTotalAmount > 0 && schoolDetails.convenienceFee">
                <span class="has-float-label">
                  <input
                    v-model="adhocFeeTotalAmount"
                    disabled
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Total Amount </label
                  >
                </span>
                 </div>
                 <div class="form-group input-group col-md-2 mt-3" v-if="adhocFeeTotalAmount > 0 && schoolDetails.convenienceFee">
                <span class="has-float-label" v-if="schoolDetails.convenienceFee == 'Rs($)'">
                  
                  <input
                    :value="schoolDetails.convenienceFee + ' ' + schoolDetails.convenienceFeeAmount"
                    disabled
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Convenience Fee </label
                  >
                </span>
                <span class="has-float-label" v-if="schoolDetails.convenienceFee == 'Percentage(%)'">
                  <input
                   :value="viewConvienceFeeForAdhocAmount(schoolDetails.convenienceFee,schoolDetails.convenienceFeePercentage)"
                    disabled
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Convenience Fee </label
                  >
                </span>
                 </div>
                 <div class="form-group input-group col-md-2 mt-3" v-if="adhocFeeTotalAmount > 0 && schoolDetails.convenienceFee">
                <span class="has-float-label">
                  <input
                   :value="addConvienceFeeForAdhocAmount(adhocFeeTotalAmount)"
                    disabled
                    class="form-control form-input"
                    id="stdFirstName"
                  />
                  <label for="clsnameid"
                    >Payable Amount </label
                  >
                </span>
                 </div>
                   
                  <!-- <div class="form-group input-group col-md-2" v-if="adhocFeeTotalAmount > 0 && schoolDetails.convenienceFee">
                    <span v-if="schoolDetails.convenienceFee == 'Rs($)'">
                    <h2 v-if="schoolDetails.convenienceFee == 'Rs($)'">convenienceFee</h2>  <span>{{ schoolDetails.convenienceFee }}</span><span> {{ schoolDetails.convenienceFeeAmount }}</span>
                    </span>
                    <span v-if="schoolDetails.convenienceFee == 'Percentage(%)'">
                    <h2 v-if="schoolDetails.convenienceFee == 'Percentage(%)'">convenienceFee {{ schoolDetails.convenienceFee }}</h2> {{ schoolDetails.convenienceFeePercentage }}
                  </span>
                  </div> -->
                  <!-- <div class="form-group input-group col-md-2" v-if="adhocFeeTotalAmount > 0 && schoolDetails.convenienceFee" style="margin-left: 3%;" >
                    <h2>Total amount</h2>{{ addConvienceFeeForAdhocAmount(adhocFeeTotalAmount) }}

                  </div> -->
                  <div class="form-group md-3" style="margin-top: 1%;">
                    <button  style="width: fit-content;"
                      @click.prevent="paymentConfirmForAdhocFee"
                      type="button"
                     
                      class="btn btnsml"
                      id="svbtn"
                    >
                      <i class="fas fa-check-circle"></i> <span>Proceed to pay</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="printSection" >
      <div style="margin-bottom: 5%">
        <!-- <div class="feedate">{{ new Date() | dateFormat }}</div> -->
        <div class="feedate" >
          <!-- <button
              type="button"
              class="btn gry_bg tooltipt mpopup ml-3"
              id="clbtn"
              @click.prevent="printDetails"
            >
              <i class="fas fa-print"></i> <span class="tooltiptext">Print</span>
            </button> -->
            <button
              type="button"
              class="btn gry_bg tooltipt mpopup ml-3"
              id="clbtn"
              @click.prevent="closePreviewPopUp"
            >
              <i class="fas fa-times-circle"></i> <span class="tooltiptext">Close</span>
            </button>
          </div>
        <div id="invoice">
          <table cellpadding="0" cellspacing="0"> 
            <tr v-if="withHeader">
              <td style="background: #2954a2;padding:0.1em">
                <table border="0">
                  <tr>
                    <td
                      style="
                        width: 20%;
                        text-align: center;
                        vertical-align: middle;
                        background: #2954a2;padding:0.1em;
                      "
                    >
                      <!--<div
                        style="
                          background: #fff;
                          border-radius: 50%;
                          width: 50%;
                          margin: 10px auto;
                          padding: 6px 0;
                        "
                      >-->
                       <img style="width:80%" v-if="studentSchoolDetails.image && studentSchoolDetails.image.path"
                        :src="studentSchoolDetails.image.path"
                        :alt="studentSchoolDetails.accountName"
                        :title="studentSchoolDetails.accountName"
                      />
                      <img v-else
                        :src="studentSchoolDetails.image"
                        :alt="studentSchoolDetails.accountName"
                        :title="studentSchoolDetails.accountName"
                      />

                     <!-- </div>-->
                    </td>
                    <td
                      style="
                        width: 80%;
                        text-align: center;
                        vertical-align: middle;
                        background: #2954a2;padding:0.1em;
                      "
                    >
                      <h1
                        style="
                          font-size: 1.9rem;
                          font-weight: 600;
                          margin: 0;
                          padding: 3px 0;
                          color: #fff;
                        "
                      >
                        {{ studentSchoolDetails.accountName }}
                      </h1>
                      <p
                        style="
                          font-size: 1rem;
                          margin: 0;
                          padding: 6px 0;
                          color: #fff;
                        "
                      >{{ studentSchoolDetails.address }}, {{ studentSchoolDetails.street }}, {{ studentSchoolDetails.district ? studentSchoolDetails.district : '' }}, {{ studentSchoolDetails.state ? studentSchoolDetails.state : '' }}, {{ studentSchoolDetails.pincode }}, {{ studentSchoolDetails.country }}
                        
                      </p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style="padding:0.1em">
                <div
                  style="
                    font-size: 1.2rem;
                    font-weight: bold;
                    text-align: center;
                    padding: 8px;
                  "
                >
                  VIEW FEE RECEIPT
                </div>
                <table style="font-size: 0.94rem">
                 
                  <tr>
                    <td
                      style="
                        padding: 4px;
                        font-weight: 600;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                      Student Name
                    </td>
                    <td
                      style="
                        padding: 4px;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                      {{ studentsDetails.firstName + " " + studentsDetails.lastName }}
                    </td>
                    <td
                      style="
                        padding: 4px;
                        font-weight: 600;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                      Roll No.
                    </td>
                    <td
                      style="
                        padding: 4px;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                      {{ studentsDetails.rollNoAllocated || '' }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        padding: 4px;
                        font-weight: 600;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                      Parent / Guardian Name
                    </td>
                    <td
                      style="
                        padding: 4px;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                      {{ studentsDetails.parent ? (studentsDetails.parent.firstName + " " + studentsDetails.parent.lastName) : '' }}
                    </td>
                    <!-- <td
                      style="
                        padding: 4px;
                        font-weight: 600;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                      Invoice No.
                    </td> -->
                    <!-- <td
                      style="
                        padding: 4px;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                      {{ historyDetails.invoiceId  || 'INV-001' }}
                    </td> -->
                    <td
                      style="
                        padding: 4px;
                        font-weight: 600;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                      Academic Year
                    </td>
                    <td
                      style="
                        padding: 4px;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                      {{ studentsDetails.academicYear ? (studentsDetails.academicYear.from + " - " + studentsDetails.academicYear.to) : '' }}
                    </td>
                  </tr>
                  <tr>
                   
                    <td
                      style="
                        padding: 4px;
                        font-weight: 600;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                    
                      Class
                    </td>
                    <td
                      style="
                        padding: 4px;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                      {{ studentsDetails.class ? studentsDetails.class.className  : '' }}
                    </td>
                    <td
                      style="
                        padding: 4px;
                        font-weight: 600;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                    
                      Classroom
                    </td>
                    <td
                      style="
                        padding: 4px;
                        width: 15%;
                        text-align: left;
                        vertical-align: middle;
                      "
                    >
                      {{ studentsDetails.section ? studentsDetails.section.name : '' }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style="padding:0.1em">
                <table class="admintbl pb-3">
                      <thead>
                          <tr>
                              <!-- <th>S.No.</th> -->
                              <th>Term Name</th>
                              <th>Fees Type</th>
                              <th>Refundable</th>
                              <th>Fee Amount ($)</th>
                              <th>Total Amount ($)</th>
                          </tr> 
                      </thead>
                      <tbody v-for="(data, index) in Object.keys(summaryInDetail)" :key="index">
                        <tr class="trmname">
                          <td colspan="4" data-label="Term Name" align="left"><b>{{ data }}</b>&nbsp;</td>
                          <td style="text-align:right"><b>{{ viewPaymentTotalFeesTerm(data) | currencyFormat }}</b>&nbsp;</td>
                        </tr>
                        <tr v-for="(ter, issndex) in summaryInDetail[data]"
                        :key="issndex">
                          <td align="left"></td>
                          <td data-label="Fees Type">{{ ter.feeTypeId ? ter.feeTypeId.name : '' }}&nbsp;</td>
                        
                          <td data-label="Refundable"> {{ ter.isRefundable ? "yes" : "No" }}&nbsp;</td>
                          <td data-label="Fee Amount ($)">{{ ter.amount | currencyFormat }}&nbsp;</td>
                          <td data-label="Total Amount ($)" class="tblnone">&nbsp;</td>
                        </tr>
                      </tbody>
                   </table>
                   <table border="0">
                     <tr class="tot">
                       <td colspan="4" style="text-align:right;font-weight:600"><b>Total Amount :</b></td>
                       <td style="text-align:right;font-weight:600"><b>{{ getTotalamounthistoryDetails() | currencyFormat}}</b>&nbsp;</td>
                     </tr>
                     <tr class="tot">
                       <td colspan="4" style="text-align:right;font-weight:600"><b>Discount Amount :</b></td>
                       <td style="text-align:right;font-weight:600"><b>{{ getDiscountAmounthistoryDetails() | currencyFormat}}</b>&nbsp;</td>
                     </tr>
                     <tr class="tot">
                       <td colspan="4" style="text-align:right;font-weight:600"><b>Sibling Discount :</b></td>
                       <td style="text-align:right;font-weight:600"><b>{{ adminOverrideDiscounthistoryDetails() | currencyFormat}}</b>&nbsp;</td>
                     </tr>
                     <tr class="tot">
                       <td colspan="4" style="text-align:right;font-weight:600"><b>Convenience Fee :</b></td>
                       <td style="text-align:right;font-weight:600"><b>{{ adminOverrideDiscounthistoryDetails() | currencyFormat}}</b>&nbsp;</td>
                     </tr>
                      <tr class="tot">
                       <td colspan="4" style="text-align:right;font-weight:600"><b>Amount Paid :</b></td>
                       <td style="text-align:right;font-weight:600"><b>{{ grandTotalAmounthistoryDetails() | currencyFormat}}</b>&nbsp;</td>
                     </tr>
                   </table>
              </td>
              <!-- <td v-else style="padding:0.1em">
                <table border="1">
                <thead>
                  <tr>
                    <td scope="col" rowspan="6" style="font-weight: 600;">
                      Fee Name
                    </td>
                    <td scope="col" rowspan="6" style="font-weight: 600;">
                      Amount
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in adhocFeeList.feeLineItem"
                    :key="index"
                  >
                    <td data-label="Fee Name"> {{ item.name }}
                    
                    </td>
                    <td data-label="Fee Name">{{ item.netAmount }}
                     
                    </td>
                  </tr>
                  <tr style="background:#e7e7e7;color: #444444;">
                    <td style="color:#2954A2"><b>Total Amount</b></td>
                    <td class="txtrgt">
                     <b>{{ getTotalNetAmount() }}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
              </td> -->
            </tr>
          
          </table>
        </div>
      </div>
    </b-modal>
    </div>
    <vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />
    
  </div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import order from "../Common/order";
import "vue-select/dist/vue-select.css";
import Select from "vue-select";

export default {
  name: "SUBSCRIPTION",

  data() {
    return {
      contactDetails: {
        firstName: "",
        lastName: "",
        admissionType: "online",
        dob: null,
        doj: null,
        dateOfLeaving: null,
        pointOfContact: "Father",
        academicYear: null,
        gender: "Male",
        parentId: null,
        accountId: null,
        email: "",
        mobileOne: 0,
        mobileTwo: 0,
        image: "",
        addressOne: "",
        addressTwo: "",
        state: "",
        district: "",
        postalCode: "",
        country: "",
        classApplied: "",
        bloodGroup: null,
        admissionStatus: "Active",
        siblings: false,
        rollNoAllocated: "",
        class: null,
        classSection: null,
        siblingName: null,
        siblingRollNo: "",
        siblingDiscount: "",
      },
      userDetails: {
        firstName: "",
        lastName: "",
        fatherName: "",
        motherName: "",
        email: "",
        phoneNo: null,
        password: "",
        role: "",
        membership: "Free",
        userId: "",
        gender: "",
        occupation: "",
        religion: "",
        nationality: "",
        address: "",
        registeredAs: "",
        userDevices: [],
        profile: null,
      },
      paymentDetails: {
        type: null,
        chequeNo: "",
        demandDraft: "",
        amount: null,
        terms: [],
      },
      studentBranchId: null,
      enrollmentStatusList: [
        "Active",
        "Inactive",
        "Submitted",
        "In Review",
        "Assessment",
        "Approved",
        "Reject",
        "Enrolled",
      ],
      pointOfList: ["Father", "Mother", "Guardian"],
      genderList: ["Male", "Female"],
      version: "",
      planDetails: {},
      studentSibilingDetails: {},
      studentDiscountDetails: {},
      payMenttypes: ["Cash", "Cheque", "Demand Draft"],
      totalSumAmount: 0,
      adminOverrideDiscount: 0,
      adminOverrideStudentDiscount: 0,
      grandTotalAmount: 0,
      showFeePlanDiscount: false,
      convenienceFeeTotalAmount: 0,
      convenienceFeeAmount: 0,
      adhocFeeTotalAmount: null,
      discountAmount: 0,
      totalPenaltyAmount: 0,
      studentBillingSummary: {},
      discountAllow: false,
      selectAcademicYear: null,
      academicYearList: [],
      studentFeePlanList: [],
      planId: null,
      adhocFeePlanId: null,
      studentAdhocFeesList: [],
      studentAcademicyear: null,
      isEdit: false,
      paymentFaildObj: {},
      schoolDetails: {},
      isRazorpayVerifiyed: '',
      razorPayAccountId: '',
      razorPayTestKeyId: '',
      razorPaySecretId: '',
      razorPayTestSecretId: '',
      isPayMentMode: false,
      viLoader: false,
      paymentDetails: {},
      historyDetails: [],
      studentsDetails: {},
      studentSchoolDetails: {},
      summary: [],
      summaryInDetail: {},
      adhocFeeList: {},
      withHeader: false,
      withFooter: false,
      bloodGroupList: ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"]
    };
  },
  created() {
    this.getStudentSummary();

  },
  computed: {
    styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
    },
    getDiscountAmount() {
      let selectedCount = 0;
      let totalTermCount = Object.values(this.studentBillingSummary).length;
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);

        if (selectedList.length == totalTermCount) {
          this.discountAllow = true;

          let totalDiscount = 0;

          for (let x of selectedList) {
          const termTotalAmount = this.totalFeesTermDiscountAvaible(x.name);
          totalDiscount = totalDiscount + termTotalAmount;
          }

          if (selectedList[0].planDiscount.discount) {
            const getDiscountDetails = selectedList[0].planDiscount.discount;
           

            if (getDiscountDetails.mode == "Rs($)") {
              const amountValue = this.totalSumAmount - (Number(getDiscountDetails.discountAmountValue) * totalDiscount );

             // const amountValue = this.totalSumAmount - Number(getDiscountDetails.discountAmountValue);
              this.grandTotalAmount = amountValue;
              this.grandTotalAmount = amountValue + this.totalPenaltyAmount;

              if (this.adminOverrideDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
              }
              if (this.adminOverrideStudentDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideStudentDiscount;
              }
              return amountValue ? (this.totalSumAmount - amountValue ) : getDiscountDetails.discountAmountValue;

              //return getDiscountDetails.discountAmountValue;
            } else {
              if(getDiscountDetails && getDiscountDetails.discountPercentageValue){

                let getvalue = 0

              for (let x of selectedList) {
              const termTotalAmount = this.totalFeesTermAmount(x.name);
              getvalue = getvalue + termTotalAmount;
              }
              
              //  const discountValue = (this.totalSumAmount * Number(getDiscountDetails.discountPercentageValue)) / 100;
              const discountValue = (getvalue * (Number(getDiscountDetails.discountPercentageValue) )) / 100;

                this.grandTotalAmount = this.totalSumAmount;
                this.grandTotalAmount = this.grandTotalAmount + this.totalPenaltyAmount;

                if (this.adminOverrideDiscount > 0) {
                  this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
                }
                if (this.adminOverrideStudentDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideStudentDiscount;
              }
                return discountValue;
             }else{
              this.grandTotalAmount = this.totalSumAmount;
              if (this.adminOverrideDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
              }
              if (this.adminOverrideStudentDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideStudentDiscount;
              }
              return 0;
             }
            }
          }

          this.grandTotalAmount = this.totalSumAmount + this.totalPenaltyAmount;
          
          if (this.adminOverrideDiscount > 0) {
            this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
          }
          if (this.adminOverrideStudentDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideStudentDiscount;
          }

        } else {
          this.discountAllow = false;
          this.grandTotalAmount = this.totalSumAmount + this.totalPenaltyAmount;

          if (this.adminOverrideDiscount > 0) {
            this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideDiscount;
          }
           if(this.adminOverrideStudentDiscount > 0) {
                this.grandTotalAmount = this.grandTotalAmount - this.adminOverrideStudentDiscount;
          }

          return 0;
        }
      }
    },
   
    getPenaltyAmountOnly() {
      this.totalPenaltyAmount = 0
      
      let finalAmount = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }

      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected && x.status != "Paid" );

        if (selectedList.length > 0) {
          for (let x of selectedList) {
           
            finalAmount = this.totalPenaltyAmountPay(x.name) + finalAmount;
          }
          this.totalPenaltyAmount = finalAmount
          return finalAmount
        
        } else {
          this.totalPenaltyAmount = 0
          return 0;
        }
      }
    },
    getconvenienceFeePercentageAmount(){
           const newValue = (this.totalSumAmount * Number(this.schoolDetails.convenienceFeePercentage)) / 100 ;
           this.convenienceFeeTotalAmount =  this.totalSumAmount + newValue
           this.convenienceFeeAmount = newValue
           return newValue
    },
  
    getTotalAmount() {
      let finalAmount = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);

        if (selectedList.length > 0) {
          for (let x of selectedList) {
            finalAmount = this.totalFeesTerm(x.name) + finalAmount;
          }
          return finalAmount;
        }
      }
    },
    towardsAllList() {
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      return allTermList.map((x) => {
        return {
          label: x.name,
          value: x.name,
        };
      });
    },
  },

  methods: {
    previousRoute(){
     let data = localStorage.getItem('previousTab');
     localStorage.setItem("activeTab",data);
     localStorage.setItem("previousTab",this.$route.path);
   //  this.$router.go(-1)
   this.$router.push({ path: `/parent/student/home/${this.$route.params.id}` });

    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    getTotalFees(values){
      let finalAmount = 0;

       if (values.length > 0) {
          for (let x of values) {
            finalAmount = x.amount + finalAmount;
          }
          return finalAmount;
       }
       
    },
    totalFeesTermDiscountAvaible(value){
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value].filter( x => x.isDiscount).length
        // return this.studentBillingSummary[value][value]
        //   .map((o) => o.amount + o.penaltyAmount)
        //   .reduce((a, c) => {
        //     return a + c;
        //   });
      }
    },
    totalPenaltyAmountPay(value) {
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.penaltyAmount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    totalFeesTermAmount(value){
      if (this.studentBillingSummary[value][value]) {
      //  return this.studentBillingSummary[value][value].filter( x => x.isDiscount).length
        return this.studentBillingSummary[value][value]
        .filter(x => x.isDiscount)
        .reduce((sum, x) => sum + x.amount, 0);
      }
    },
    addConvienceFeeAmount(){
      if(this.schoolDetails.convenienceFee){
        if(this.schoolDetails.convenienceFee == 'Rs($)'){
           const totalSumAmount = this.totalSumAmount + Number(this.schoolDetails.convenienceFeeAmount)   
           this.convenienceFeeTotalAmount =  totalSumAmount
           this.convenienceFeeAmount = Number(this.schoolDetails.convenienceFeeAmount)
           //return  totalSumAmount
        }else{
          //  const newValue = (this.totalSumAmount * Number(this.schoolDetails.convenienceFeePercentage)) / 100 ;
          //  this.convenienceFeeTotalAmount =  this.totalSumAmount + newValue
          //  this.convenienceFeeAmount = newValue

          // return  this.convenienceFeeTotalAmount

        }
       
      }
     
      
    },
     addConvienceFeeForAdhocAmount(value){
      if(this.schoolDetails.convenienceFee){
        if(this.schoolDetails.convenienceFee == 'Rs($)'){
           const totalSumAmount = this.adhocFeeTotalAmount + Number(this.schoolDetails.convenienceFeeAmount)   
           this.convenienceFeeTotalAmount =  totalSumAmount
           this.convenienceFeeAmount = Number(this.schoolDetails.convenienceFeeAmount)   
           return  totalSumAmount.toFixed(2)
        }else{
           const penaltyValue = (this.adhocFeeTotalAmount * Number(this.schoolDetails.convenienceFeePercentage)) / 100 ;
           this.convenienceFeeTotalAmount =  this.adhocFeeTotalAmount + penaltyValue
           this.convenienceFeeAmount = penaltyValue 

           return  this.convenienceFeeTotalAmount.toFixed(2)

        }
       
      }
     
      
    },
    viewConvienceFeeForAdhocAmount(type,value){
     // if(this.schoolDetails.convenienceFee){
        if(type == 'Rs($)'){
           const totalSumAmount = Number(value)   
         //  this.convenienceFeeTotalAmount =  totalSumAmount
          // this.convenienceFeeAmount = Number(this.schoolDetails.convenienceFeeAmount)   
           return  totalSumAmount.toFixed(2)
        }else{
           const penaltyValue = (this.adhocFeeTotalAmount * Number(value)) / 100 ;
           //this.convenienceFeeTotalAmount =  this.adhocFeeTotalAmount + penaltyValue
           //this.convenienceFeeAmount = penaltyValue 

           return  penaltyValue.toFixed(2)

        }
       
     // }
     
      
    },
    
    getTotalAdhocFees(values){
      let finalAmount = 0;

       if (values.length > 0) {
          for (let x of values) {
            if(x.feeLineItem.length > 0){
              for(let y of x.feeLineItem){
              finalAmount = y.netAmount + finalAmount;
              }
            }
          }
          return finalAmount;
       }

    },
  
   
    getTotalNetAmount() {
      if (this.studentAdhocFeesList[0].feeLineItem.length > 0) {
        let totalAmount = 0;

        for (let x of this.studentAdhocFeesList[0].feeLineItem) {
          if (x.netAmount) {
            totalAmount = totalAmount + Number(x.netAmount);
          }
        }
        this.adhocFeeTotalAmount = totalAmount
        return totalAmount;
      } else {
        return 0;
      }
    },

    checkSelected(term, value, index) {
      Object.values(this.studentBillingSummary).forEach((x, i) => {
        if (value) {
          if (i < index && x.status != "Paid") {
            x.isSelected = true;
          }
        } else {
          if (i > index && x.status != "Paid") {
            x.isSelected = false;
          }
        }
      });

      let finalAmount = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected && x.status != "Paid" );
        if(selectedList.length == allTermList.length){
          this.showFeePlanDiscount = true
        }else{
          this.showFeePlanDiscount = false
        }

        if (selectedList.length > 0) {

          if(this.showFeePlanDiscount){
            for (let x of selectedList) {
            finalAmount = this.totalFeesTermPayOnly(x.name) + finalAmount;
           }

          let dddtotalSumAmount = finalAmount;


            this.totalSumAmount = (dddtotalSumAmount - this.getDiscountAmount)

          }else{

            for (let x of selectedList) {
            finalAmount = this.totalFeesTerm(x.name) + finalAmount;
          }
          this.totalSumAmount = finalAmount;
          }

          
        }else{
          this.totalSumAmount = 0
        }
        
      }

      this.getSibilingDiscountAmount();
      this.getStudentDiscountAmount()
    },
    totalFeesTermPayOnly(value) {
       if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.amount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    async proceedToPayment() {
      const response = await order(100);

      if (response.isSuccess) {
        this.paymentConfirm();
      }
    },
    previewConfirmPopUp(){
    this.$bvModal.show("printSection");
  },
  closePreviewPopUp (){
     this.paymentDetails = {}
     this.$bvModal.hide("printSection");
  },
  async printPreview(data) {
   
    if(data._id){

      localStorage.setItem("activeTab",`/parent/student/paymentHistory/${data.studentId}`);
      localStorage.setItem("previousTab",this.$route.path);

      this.$router.push({ path: `/parent/student/paymentHistory/${data.studentId}` });
      //  let userData = secureUI.sessionGet("user");
       
      // if (!userData) {
      //   this.$toasted.error("Please login and do the action");
      // } else {
      //   const response = await ViService.viXGet(`/contact/getFullPaymentInvoiceDetails?studentId=${data.studentId}&feePlanId=${data.feePlanId}`,userData.token);
 
      //   if (response.isSuccess) {
      //       const data = secureUI.secureGet(response.data);
      //       this.historyDetails  = data.historyDetails || [],
      //       this.studentsDetails = data.students || {},
      //       this.studentSchoolDetails = data.schoolDetails || {}
            
      //     //  if(this.historyDetails.type == 'Term-Fees'){
      //          this.summary = data.summary || [],
      //          this.summaryInDetail = data.summaryInDetail || {}
      //          this.adhocFeeList = {}
      //      // }

      //       // if(this.historyDetails.type == 'Adhoc-Fees'){
      //       //    this.adhocFeeList = data.adhocSummeryDetails || {},
      //       //    this.summary =  [],
      //       //    this.summaryInDetail = {}
      //       // }
      //   } else {
      //     this.$toasted.error(response.message);
      //      if(response.message == 'Your session has expired, please login'){
      //           localStorage.removeItem('user');
      //           this.$router.push('/login');
      //    }
      //   }
      //   // if(userData.userDetails.profile.name == "Parent"){
      //     this.$bvModal.show("printSection");
      //     this.withHeader = true,
      //     this.withFooter = true
      //   //  // this.$router.push("/parent/search/home");
      //   // }else{
      //   //   this.$bvModal.show("viewPayMentDetailsPreview");

      //   // }

      // }
     
    }
    
  },
    async printDetails() {
    
      let section = document.getElementById("print");

      if (!section) {
        section = document.createElement("div");
        

        section.id = "print";
        document.body.appendChild(section);
      }

      section.innerHTML = "";
      window.print();
    },
    // async print() {
    //   const modal = document.getElementById("printSection").shadowRoot.innerHTML.toString().trim();;
    //   const cloned = modal.cloneNode(true);
    //   let section = document.getElementById("print");

    //   if (!section) {
    //     section = document.createElement("div");
    //     section.id = "print";
    //     document.body.appendChild(section);
    //   }

    //   section.innerHTML = "";
    //   section.appendChild(cloned);
    //   window.print();
    // },
    getdiscountAmountTerm(data){
      if(this.planDetails && this.planDetails.discount && this.planDetails.discount.mode){
            if(this.planDetails.discount.mode == 'Rs($)'){
           //  let value = `Rs: ${this.planDetails.discount.discountAmountValue}`
             return this.planDetails.discount.discountAmountValue

            }else{
              return this.planDetails.discount.discountPercentageValue + "%"

            }
          }else{
            return 0
          }
     },
    getSibilingDiscountAmount() {
      
      let finalAmount = 0;

      let calculateTermTotalAmount = 0;
      let discountValue = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);

        if (selectedList.length > 0) {
          for (let x of selectedList) {
            if (this.studentSibilingDetails.toWards.indexOf(x.name) != -1) {
              const termTotalAmount = this.totalFeesTerm(x.name);
              discountValue = (termTotalAmount * Number(this.studentSibilingDetails.siblingDiscount)) / 100;
              finalAmount = finalAmount + discountValue;
            }
          }
          this.adminOverrideDiscount = finalAmount;
        }else{
          this.adminOverrideDiscount = 0;
        }
      }
    },
    totalFeesTermWithDiscount(value) {
      if (this.studentBillingSummary[value][value]) {
       
        let totalTermCount = Object.values(this.studentBillingSummary).length;
     
      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }

      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);
         
        if (selectedList.length == totalTermCount) {
          let getValuetotalAmount = 0
          let getvalue = 0
        for(let x of this.studentBillingSummary[value][value]){
          if(x.isDiscount){
            getvalue = (getvalue + x.amount)

          }else{
            getValuetotalAmount = (getValuetotalAmount + x.amount)
          }
        }

        const checkDiscount = this.studentBillingSummary[value][value].filter( x => x.isDiscount).length

         if(this.planDetails && this.planDetails.discount && this.planDetails.discount.mode){
            if(this.planDetails.discount.mode == 'Rs($)'){
              getvalue = ((getvalue - (Number(this.planDetails.discount.discountAmountValue) * checkDiscount) ))
              return getvalue + getValuetotalAmount
            }else{
              let getDiscountValue =  (getvalue * (Number(this.planDetails.discount.discountPercentageValue) )) / 100;
              return ((getvalue - getDiscountValue)  + getValuetotalAmount);
            }
            
          }else {
          return this.studentBillingSummary[value][value]
          .map((o) => o.amount + o.penaltyAmount)
          .reduce((a, c) => {
            return a + c;
          });
        }
        } else {
          return this.studentBillingSummary[value][value]
          .map((o) => o.amount + o.penaltyAmount)
          .reduce((a, c) => {
            return a + c;
          });
        }
      }

       
      } else {
        return 0;
      }
    },
    getStudentDiscountAmount() {
      let finalAmount = 0;

      let calculateTermTotalAmount = 0;
      let discountValue = 0;

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);
         
        if (selectedList.length > 0) {
          for (let x of selectedList) {
            if (this.studentDiscountDetails.studentDiscountToWards.indexOf(x.name) != -1) {
              const termTotalAmount = this.totalFeesTerm(x.name);
              discountValue = (termTotalAmount * Number(this.studentDiscountDetails.studentDiscountAmount)) / 100;
              finalAmount = finalAmount + discountValue;
            }
          }
          this.adminOverrideStudentDiscount = finalAmount;
        }else{
          this.adminOverrideStudentDiscount = 0;
        }
      }
    },
    clearValues(value) {
      (this.paymentDetails.chequeNo = ""),
        (this.paymentDetails.demandDraft = ""),
        (this.paymentDetails.amount = this.grandTotalAmount);
    },
    showDateFormat(value) {
      if (value) {
        const dtf = new Intl.DateTimeFormat("en", {
          year: "2-digit",
          month: "short",
          day: "2-digit",
        });
        const [{ value: mo }, , { value: da }, , { value: ye }] =
          dtf.formatToParts(new Date(value));

        return `${da}-${mo}-${ye}`;
      } else {
        return "";
      }
    },
    totalFeesTerm(value) {
      if (this.studentBillingSummary[value][value]) {
        return this.studentBillingSummary[value][value]
          .map((o) => o.amount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    
    viewPaymentTotalFeesTerm(value) {
      if (this.summaryInDetail[value]) {
        return this.summaryInDetail[value]
          .map((o) => o.amount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    getTotalamounthistoryDetails() {
      if (this.historyDetails.length > 0) {
        return this.historyDetails
          .map((o) => o.totalSumAmount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    getDiscountAmounthistoryDetails() {
      if (this.historyDetails.length > 0) {
        return this.historyDetails
          .map((o) => o.getDiscountAmount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
 adminOverrideDiscounthistoryDetails() {
      if (this.historyDetails.length > 0) {
        return this.historyDetails
          .map((o) => o.adminOverrideDiscount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
 grandTotalAmounthistoryDetails() {
      if (this.historyDetails.length > 0) {
        return this.historyDetails
          .map((o) => o.grandTotalAmount)
          .reduce((a, c) => {
            return a + c;
          });
      } else {
        return 0;
      }
    },
    
    async paymentConfirm() {

      const allTermList = [];

      for (let x of Object.values(this.studentBillingSummary)) {
        allTermList.push(x);
      }
      if (allTermList.length > 0) {
        const selectedList = allTermList.filter((x) => x.isSelected);

        if (selectedList.length > 0) {

          const terms = selectedList.map((x) => x.name);

            if(this.razorPayAccountId){
      

      

            let grandTotalAmount = this.grandTotalAmount + this.convenienceFeeAmount
          // let grandTotalAmount =  this.convenienceFeeTotalAmount || 0
            let payAmount = grandTotalAmount * 100

           // this.grandTotalAmount = null
            
            let processId = ''
            let processSecretId = ''

            if(this.isPayMentMode){
                  processId = this.isRazorpayVerifiyed
                  processSecretId = this.razorPaySecretId
            }else{
                  processId = this.razorPayTestKeyId
                  processSecretId = this.razorPayTestSecretId
            }
            
            if(processId){

              payAmount = Math.round(payAmount)
            //  this.updateStudentTerms(grandTotalAmount);

              const response = await order(payAmount,this.razorPayAccountId,processId,processSecretId,this.isPayMentMode);
              
              if (response.isSuccess) {
                  if(response.message != 'Payment Failed'){
                        this.$toasted.success(response.message);
                        this.paymentDetails.terms = terms;
                        this.paymentDetails["razorPayPaid"] = response.data
                        this.paymentDetails["actionType"] = 'Parent'
                        this.paymentDetails['planDetails'] = this.planDetails
                        this.paymentDetails['sibiling'] = this.studentSibilingDetails
                        this.updateStudentTerms(grandTotalAmount);
                  }else{
                  this.paymentFaildObj = response.data
                  this.paymentDetails.terms = terms;
                  this.updatePaymentFailureInTermFee()
                  }
                  
              } else {
                this.$toasted.error(response.message);
              }
            }else{
              this.$toasted.error("The payment mode is not activated. Please contact school.");
            }
            }else{
              this.$toasted.error("The payment mode is not activated. Please contact school.");
            }
        } 
        else{
            this.$toasted.error("Please select terms");
        }
      }
    },
    async paymentConfirmForAdhocFee() {
         if (this.adhocFeePlanId) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
           this.adhocFeeTotalAmount = this.adhocFeeTotalAmount + this.convenienceFeeAmount
           let payAmount = this.adhocFeeTotalAmount * 100

         if(this.razorPayAccountId){

          let processId = ''
          let processSecretId = ''

         if(this.isPayMentMode){
              processId = this.isRazorpayVerifiyed
              processSecretId = this.razorPaySecretId
         }else{
              processId = this.razorPayTestKeyId
              processSecretId = this.razorPayTestSecretId
         }

          if(processId){

          payAmount = Math.round(payAmount)

          const response = await order(payAmount,this.razorPayAccountId,processId,processSecretId,this.isPayMentMode);
          
          if (response.isSuccess) {
            if(response.message != 'Payment Failed'){
             let obj = {
                amount: this.adhocFeeTotalAmount,
                razorPayPaid : response.data,
                actionType : 'Parent',
                academicYear: this.studentAcademicyear,
                academicYearValue: this.contactDetails.academicYear
             }
            
            const updateResponse = await ViService.viXPut(
              `/feesManagement/updateStudentsAdhocFeePlan/${this.$route.params.id}/${this.adhocFeePlanId}/${this.contactDetails.accountId}`,
              obj,
              userData.token
            );

            if (updateResponse.isSuccess) {
              this.$toasted.success(updateResponse.message);
              this.closeSummaryPaymentPopUp();
            } else {
              this.$toasted.error(updateResponse.message);
            }
            }else{               
               this.paymentFaildObj = response.data
               this.paymentFaildObj["grandTotalAmount"] = this.adhocFeeTotalAmount;
               this.paymentFaildObj["type"] = 'Adhoc-Fees',
               this.paymentFaildObj["status"] = 'Failed',
               this.paymentFaildObj["paymentBy"] = 'Parent',
               this.paymentFaildObj["razorPayResponse"] = {},
               this.paymentFaildObj.razorPayResponse.razorpay_payment_id = this.paymentFaildObj.error.metadata.payment_id
               this.paymentFaildObj.razorPayResponse.razorpay_order_id = this.paymentFaildObj.error.metadata.order_id

          const UpdateResponse = await ViService.viXPut(
            `/feesManagement/updateStudentsPaymentFailure/${this.$route.params.id}/${this.contactDetails.accountId}`,
            this.paymentFaildObj,
            userData.token
          );

          if (UpdateResponse.isSuccess) {
           // this.closeSummaryPaymentPopUp();
          } else {
            this.$toasted.error(UpdateResponse.message);
          }
          }
          } else {
            this.$toasted.error("Payment Failure");
          }
         }else{
           this.$toasted.error("The payment mode is not activated. Please contact school.");
         }

         }else{
           this.$toasted.error("The payment mode is not activated. Please contact school.");
         }
        }
      }
    },

    async updateStudentTerms(amount) {
      if (this.planId) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          
          this.paymentDetails["totalSumAmount"] = this.totalSumAmount;
          this.paymentDetails["getDiscountAmount"] = this.getDiscountAmount;
          this.paymentDetails['getPenaltyAmountOnly'] = this.getPenaltyAmountOnly || 0
          this.paymentDetails["convenienceFeeTotalAmount"] = this.convenienceFeeTotalAmount;
          this.paymentDetails["adminOverrideDiscount"] = this.adminOverrideDiscount;
          this.paymentDetails["grandTotalAmount"] = this.grandTotalAmount;
          this.paymentDetails["paidTotalAmount"] = amount;
          this.paymentDetails["academicYear"] = this.studentAcademicyear;
          this.paymentDetails["academicYearValue"] = this.contactDetails.academicYear;

          const response = await ViService.viXPut(
            `/feesManagement/updateStudentsTermsAndFeePlan/${this.$route.params.id}/${this.planId}/${this.contactDetails.accountId}`,
            this.paymentDetails,
            userData.token
          );

          if (response.isSuccess) {
            this.closeSummaryPaymentPopUp();
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
    async updatePaymentFailureInTermFee() {
      if (this.paymentFaildObj) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          
          
               this.paymentFaildObj["grandTotalAmount"] = this.grandTotalAmount;
               this.paymentFaildObj["type"] = 'Term-Fees',
               this.paymentFaildObj["status"] = 'Failed',
               this.paymentFaildObj["paymentBy"] = 'Parent',
               this.paymentFaildObj["termsPaid"] = data.terms,
               this.paymentFaildObj["razorPayResponse"] = this.paymentFaildObj.error.metadata

          const response = await ViService.viXPut(
            `/feesManagement/updateStudentsPaymentFailure/${this.$route.params.id}/${this.contactDetails.accountId}`,
            this.paymentFaildObj,
            userData.token
          );

          if (response.isSuccess) {
            //this.closeSummaryPaymentPopUp();
          } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
          }
        }
      }
    },
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");
      
      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/academicYear/getAcademicYearListForSchool?accountId=${this.contactDetails.accountId}&studentBranchId=${this.studentBranchId}`,
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    getSelectRange() {
      if (this.selectAcademicYear) {
        this.getStudentFeePlanList();
      }
    },
    async getStudentSummary() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.viLoader = true
        const response = await ViService.viXGet(
          `/contact/getStudentSummary?studentId=${this.$route.params.id}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);

          this.contactDetails = studentDetails;
          this.studentBranchId = studentDetails.branchId

          if (this.contactDetails.dob) {
            this.contactDetails.dob = this.contactDetails.dob.substring(0, 10);
          }
          if (this.contactDetails.doj) {
            this.contactDetails.doj = this.contactDetails.doj.substring(0, 10);
          }
          if (this.contactDetails.dateOfLeaving) {
            this.contactDetails.dateOfLeaving =
              this.contactDetails.dateOfLeaving.substring(0, 10);
          }

          this.selectAcademicYear = studentDetails.academicYear._id;
          this.studentAcademicyear = studentDetails.academicYear._id;

          this.contactDetails.class = this.contactDetails.class.className;
          this.contactDetails.classSection = this.contactDetails.classSection.name;
          this.contactDetails.academicYear = this.contactDetails.academicYear.from + " - " + this.contactDetails.academicYear.to;

          this.userDetails = studentDetails.parent;
          this.getAccountDetails();
          this.getAcademicYearList();
          this.getStudentFeePlanList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false
      }
    },

    async getAccountDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet("/account/getAccountConfigDetails?accountId=" + this.contactDetails.accountId,userData.token);

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);
          
          if (details.version) {
            this.version = details.version;
            this.razorPayAccountId = details.razorPayAccountId || ''
            this.isRazorpayVerifiyed = details.razorPayKeyId || '';
            this.razorPayTestKeyId = details.razorPayTestKeyId || ''
            this.razorPaySecretId = details.razorPaySecretId || ''
            this.razorPayTestSecretId = details.razorPayTestSecretId || ''
            this.schoolDetails = details
            
            this.isPayMentMode = details.isPayMentMode || false
          }
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async viewStudentTerms(studentId, planId,data) {
      
      if(data.status == 'Full Paid'){
        await this.printPreview(data)
      }else{
        this.planId = planId;
      await this.getBillingSummaryList();
      await this.addConvienceFeeAmount()
      this.$bvModal.show("showSummaryPopUp");
      }
     
    },
    async viewStudentAdhocTerms(studentId, planId) {
      this.adhocFeePlanId = planId;
      await this.getAdhocFeesSummaryList();
      this.$bvModal.show("showAdhocFeeSummaryPopUp");
    },
    closeSummaryPopUp() {
      this.planId = null;
      this.$bvModal.hide("showSummaryPopUp");
      this.studentBillingSummary = {};
    },
    closeAdhocSummaryPopUp() {
      this.adhocFeePlanId = null;
      this.$bvModal.hide("showAdhocFeeSummaryPopUp");
      this.studentAdhocFeesList = [];
    },

    closeSummaryPaymentPopUp() {
      this.planId = null;
      this.$bvModal.hide("showSummaryPopUp");
      this.studentBillingSummary = {};
      (this.paymentDetails.type = null),
        (this.paymentDetails.chequeNo = ""),
        (this.paymentDetails.demandDraft = ""),
        (this.paymentDetails.amount = null),
        (this.paymentDetails.terms = []);
      this.adhocFeePlanId = null;
      this.$bvModal.hide("showAdhocFeeSummaryPopUp");
      this.studentAdhocFeesList = [];
      this.getStudentFeePlanList();
    },
    async getBillingSummaryList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/contact/getBillingSummaryList?studentId=${this.$route.params.id}&feePlanId=${this.planId}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);

          this.studentBillingSummary = studentDetails.summaryList;
          this.studentSibilingDetails = studentDetails.studentData;
          this.studentDiscountDetails = studentDetails.studentData;

          this.planDetails = studentDetails.planDetails || {};

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getAdhocFeesSummaryList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/contact/getAdhocSummaryDetails?studentId=${this.$route.params.id}&feePlanId=${this.adhocFeePlanId}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);
          this.studentAdhocFeesList = studentDetails;

          for (let x of this.studentAdhocFeesList) {
            x.isSelected = false;
          }
          
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },

    async getStudentFeePlanList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        this.studentFeePlanList = [];
        const response = await ViService.viXGet(
          `/contact/getStudentFeePlanList?studentId=${this.$route.params.id}&academicYear=${this.selectAcademicYear}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);
          this.studentFeePlanList = studentDetails;
         
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
  },
  components: {
    "v-select": Select,
    ViSpinner
  },
};
</script>
<style scoped>
ul,
ul li,
p,
div,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

#invoice {
  margin: 0 auto;
  width: 999px;

  clear: both;
  position: relative;
  background: #fff;
}
.feedate {
  font-size: 0.78rem;
  text-align: right;
  margin: 0 auto;
  padding: 10px 0;
  width: 999px;
}
table,
td,
th {
  border: 1px solid black;
}

@media screen {
  #print {
    display: none;
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #print,
  #print * {
    visibility: visible;
  }
  #print {
    position: absolute;
    left: 0;
    top: 0;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}

/* table{
         border-collapse:collapse;
         width:100%;
        clear:both;
      } */
</style>